@import './fonts/inter';
@import './fonts/material-icons';

@import './bootstrap';
@import './colors';
@import './theme';

* {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: 'Inter';
}

body {
    height: 100vh;
}

a {
    color: $color-black;
    font-weight: 600;
    text-decoration: none;
}

// Mat Buttons .mat-raised-button,
.mat-stroked-button {
    border-radius: 20px !important;
    padding: 8px 16px !important;
    line-height: 24px !important;
    box-shadow: none !important;
}

// Mat Form Field

.mat-form-field-flex > .mat-form-field-infix {
    padding: 0.4em 0 !important;
}

.mat-form-field-underline {
    display: none;
}

.mat-form-field-flex {
    border-radius: 4px !important;
    padding: 8px 12px !important;
    background-color: white !important;
    border: 1px solid map-get($palette-primary, 400);
}

.mat-form-field-suffix {
    color: map-get($palette-primary, 400);
}

.small-input {
    .mat-form-field-infix {
        border: none !important;
    }

    .mat-form-field-flex {
        align-items: center !important;
    }

    .mat-form-field-wrapper {
        height: 40px !important;
        padding-bottom: 0 !important;
    }
}

// Toggle
.mat-slide-toggle-thumb {
    background-color: $color-white !important;
}

.mat-checked .mat-slide-toggle-bar {
    background-color: $color-green !important;
}

.mat-slide-toggle-bar {
    width: 40px !important;
    height: 24px !important;
    border-radius: 12px !important;
}

.mat-slide-toggle-thumb-container {
    top: 2px !important;
    left: 2px !important;
}

a.mat-raised-button,
button.mat-raised-button {
    &:hover {
        background-color: $color-primary-light;
        &.mat-primary {
            background-color: $color-primary-grey;
        }
    }
}

a.mat-raised-button,
button.mat-button,
button.mat-raised-button {
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    border-radius: 160px;
    padding: 0 20px;
}

button.mat-icon-button {
    padding: 0;
}

a.link:hover {
    cursor: pointer;
}

mat-form-field.mat-form-field-invalid .mat-form-field-flex {
    border-color: $color-red !important;
}

mat-form-field.edit-field {
    * {
        cursor: pointer;
    }
    mat-icon {
        transform: translateY(6px);
    }
}

.mat-dialog-container {
    background: $color-white;
    border: 1px solid map-get($palette-primary, 300);
    box-sizing: border-box;
    padding: 24px 0px 0px 0px !important;
}

.resolution-warning {
    .mat-simple-snackbar-action {
        color: $color-white;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.btnspinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid $color-white;
    border-top-color: $color-black;
    animation: spinner 0.8s linear infinite;
}

.mat-dialog-content {
    max-height: initial;
    margin: 0 24px 24px 24px !important;
    padding: 0 !important;
    h4 {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 16px;
        margin-top: 0;
        margin-bottom: 0px;
    }
}

.dialog-head.mat-dialog-title {
    display: flex;
    justify-content: space-between;
    position: static;
    width: 100%;
    height: 32px;
    padding: 0 24px 24px 24px !important;
    & > .dialog-title h3 {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        margin: 0 auto;
    }

    & > .dialog-close {
        display: flex;
        align-items: baseline;
        justify-content: center;
        & > button {
            margin-top: -5px;
            & img {
                width: 14px;
                height: 14px;
            }
        }
    }
}

.mat-progress-bar-fill::after {
    background-color: $color-red;
}

mat-radio-button {
    & > label > span.mat-radio-label-content {
        color: $color-black-gray;
    }

    &.mat-radio-checked {
        & > label > span.mat-radio-label-content {
            color: #000;
        }
    }
}

.toggle-inactive {
    color: $color-black-gray;
}

mat-slide-toggle.reuse-existing-toggle {
    margin-top: 10px;
    margin-bottom: 45px;
    .mat-slide-toggle-label {
        & > .mat-slide-toggle-content {
            margin-top: 25px;
            & > .toggle-note {
                color: $color-black-gray;
                display: block;
                font-size: 12px;
                line-height: 18px;
                padding-top: 8px;
            }
            & > .toggle-label {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.mat-dialog-actions {
    margin-top: 24px;
    width: 100%;
    background-color: #fcfcfc;
}
